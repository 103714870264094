<template>
  <div v-if="blackScreen" id="black-screen">

  </div>
  <div
      :style="{'background': theme === 'biatFirst' || theme === 'biatSecond' || theme === 'biatThird' ? '#E9E4E1' : ''}"
      class="display-container">
    <LbrxDisplayHeader
        v-if="theme === 'biatFirst' || theme === 'biatSecond' || theme === 'biatThird' || theme === 'dynamicStyle'" :image="config.photo" :theme="theme"></LbrxDisplayHeader>
    <div v-if="theme !== 'verticalTheme'" class="display-table"
         :style="{'justify-content': !data.length ? 'center' : ''}"
         :class="{'justify-content-between': data.length}">
      <div class="display-image-container" :style="{'width': !data.length ? '100% !important': ''}">
        <div class="biatVidContainer"
             v-if="theme === 'biatFirst' || theme === 'biatSecond' || theme === 'biatThird' || theme === 'dynamicStyle'">
          <div v-if="activeItem" :style="{'width': !data.length ? '100% !important': ''}"
               style="display: flex;flex-direction: column;height: fit-content;margin-top: auto;margin-bottom: auto">
            <div style="display: flex;justify-content: center;width: 100%;padding-left: 0vmin">
              <video
                  v-if="activeItem.type === 'video'"
                  style="height: 50vh !important"
                  id="my-video"
                  :style="{'width': data.length ? '100% !important': '75%','height': !data.length ? '75vh !important': ''}"
                  autoplay
                  loop
                  muted
                  :poster="photo"
              >
                <source :src="videoSrc" type="video/webm"/>
                Your browser does not support the video tag.
              </video>
              <img v-else style="box-shadow: 0px 2px 2px 0px #00000040;"
                   :style="{'width': data.length ? '100% !important': '75%','height': !data.length ? '75vh !important': ''}"
                   :src="imageSrc"/>

            </div>
            <div class="pointers">
              <div v-for="item in items" :key="item" class="pointer"
                   :class="{'active-pointer': item === activeItem}"></div>
            </div>
          </div>
        </div>
        <div v-else-if="video_link === ''" style="display: flex;height: 100%">
          <img style="margin: auto"
               :style="{'width': data.length ? '100% !important': '75%','height': !data.length ? '75vh !important': ''}"
               :src="photo"/>
        </div>
        <div v-else
             style="
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;">
          <video
              id="my-video"
              height="100%"
              autoplay
              loop
              :poster="photo"
          >
            <source :src="video_link" type="video/webm"/>
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div v-if="data.length > 0" ref="dataTable"
           :style="{'max-height': theme === 'biatFirst' || theme === 'biatSecond' || theme === 'biatThird' ? '90vh' : '96vh'}"
           class="display-box-hori">
        <lbrx-display-first-theme v-if="theme === 'liberrex'"
                                  :secondary-text-color="colorText"
                                  :secondary-color="'#F2F2F2'"
                                  :second-primary-color="colorSecondary"
                                  :first-primary-color="color"
                                  :first-title="textNumber"
                                  :second-title="textCounter"
                                  :data="data"
        ></lbrx-display-first-theme>

        <lbrx-display-single-bloc-theme v-if="theme === 'singleBloc'"
                                        :secondary-text-color="colorText"
                                        :secondary-color="'#F2F2F2'"
                                        :second-primary-color="colorSecondary"
                                        :first-primary-color="color"
                                        :first-title="textNumber"
                                        :second-title="textCounter"
                                        :data="data"
        ></lbrx-display-single-bloc-theme>

        <lbrx-display-second-theme v-if="theme === 'leaf'"
                                   :secondary-text-color="colorText"
                                   :secondary-color="'#F2F2F2'"
                                   :second-primary-color="colorSecondary"
                                   :first-primary-color="color"

                                   :first-title="textNumber"
                                   :second-title="textCounter"
                                   :data="data"
        ></lbrx-display-second-theme>
        <lbrx-display-third-theme v-if="theme === 'boxes'"
                                  :data="data"
                                  :primary-color="color"
                                  :secondary-color="colorSecondary"
                                  :text-color="colorText"
        ></lbrx-display-third-theme>
        <lbrx-display-biat-first-theme v-if="theme === 'biatFirst'"
                                       :data="data"
                                       :first-title="textNumber"
                                       :second-title="textCounter"
        ></lbrx-display-biat-first-theme>
        <lbrx-display-biat-second-theme v-if="theme === 'biatSecond'"
                                        :data="data"
        ></lbrx-display-biat-second-theme>
        <lbrx-display-biat-third-theme v-if="theme === 'biatThird'"
                                       :data="data"
                                       :first-title="textNumber"
                                       :second-title="textCounter"
        ></lbrx-display-biat-third-theme>
        <lbrx-display-dynamic-style v-if="theme === 'dynamicStyle'"
                                      :secondary-text-color="colorText"
                                      :secondary-color="'#F2F2F2'"
                                      :second-primary-color="colorSecondary"
                                      :first-primary-color="color"
                                       :data="data"
                                       :first-title="textNumber"
                                       :second-title="textCounter"
        ></lbrx-display-dynamic-style>
      </div>
    </div>
    <div v-else class="display-box-vert" style="margin-top: 2vmin;">
      <lbrx-display-vertical-theme v-model="onLine"

                                   :text-color="colorText"
                                   :primary-color="color"

                                   :second-title="textCounter"
                                   :first-title="textNumber"

                                   :data="data">

      </lbrx-display-vertical-theme>
    </div>
    <div v-if="theme !== 'verticalTheme'" class="footer-container">
      <the-footer
          :color="theme === 'biatFirst' || theme === 'biatSecond' || theme === 'biatThird'? 'rgba(0, 0, 0, 0.35)' : color"
          :internet="onLine"></the-footer>
    </div>
  </div>
</template>

<script>
import LbrxDisplayFirstTheme from "@/components/LbrxDisplayFirstTheme.vue";
import LbrxDisplaySecondTheme from "@/components/LbrxDisplaySecondTheme.vue";
import LbrxDisplayThirdTheme from "@/components/LbrxDisplayThirdTheme.vue";
import TheFooter from "@/components/TheFooter.vue";
import {displayService} from "@/_services/display.service";
import LbrxDisplayVerticalTheme from "@/components/LbrxDisplayVerticalTheme.vue";
import LbrxDisplaySingleBlocTheme from "@/components/LbrxDisplaySingleBlocTheme.vue";
import {setLocalVoiceBydisplayLang} from "@/_helpers/voice-helper";
import router from "@/router";
import html2canvas from "html2canvas";
import LbrxDisplayBiatFirstTheme from "@/components/LbrxDisplayBiatFirstTheme.vue";
import LbrxDisplayDynamicStyle from "@/components/LbrxDisplayDynamicStyle.vue";
import LbrxDisplayHeader from "@/components/LbrxDisplayHeader.vue";
import LbrxDisplayBiatSecondTheme from "@/components/LbrxDisplayBiatSecondTheme.vue";
import LbrxDisplayBiatThirdTheme from "@/components/LbrxDisplayBiatThirdTheme.vue";

export default {
  name: 'DisplayPage',
  inject: ['echo', 'display', 'snd', 'audioNotification'],
  components: {
    LbrxDisplayBiatThirdTheme,
    LbrxDisplayBiatSecondTheme,
    LbrxDisplayHeader,
    LbrxDisplayBiatFirstTheme,
    LbrxDisplaySingleBlocTheme,
    LbrxDisplayVerticalTheme,
    TheFooter, LbrxDisplayThirdTheme, LbrxDisplaySecondTheme, LbrxDisplayFirstTheme,
    LbrxDisplayDynamicStyle
  },
  data() {
    return {
      theme: 'liberrex',
      color: '#008FCA',
      colorSecondary: '#4BB0DA',
      colorText: '#FFFFFF',
      queues: '',
      locale: 'fr',
      photo: '/images/display-image.png',
      textCounter: 'Guichet',
      textNumber: 'Numero',
      langCounter: {'en': 'Counter', 'fr': 'Guichet', 'ar': 'شباك'},
      langNumber: {'en': 'Number', 'fr': 'Numéro', 'ar': 'رقم'},
      video_link: '',
      config: {},
      needsRecal: true,
      data: [],
      nextTickets: [],
      voice_is_playing: false,
      voices_to_play: [],
      onLine: navigator.onLine,
      showBackOnline: false,
      themes: ['liberrex', 'singleBloc', 'leaf', 'boxes', 'biatFirst', 'biatSecond', 'biatThird', 'dynamicStyle'],
      getImageWidth: '70vh',
      items: [],
      activeItem: null,
      blackScreen: false,
      isConnected: false,
      intervalId: null,
      audio: null
    }
  },
  methods: {
    checkConnection() {
        this.isConnected = this.echo.connector.pusher.connection.state === 'connected';
        console.log(`WebSocket connection status: ${this.isConnected ? 'connected' : 'disconnected'}`);
        
        // Optional: Handle reconnection logic here
        if (!this.isConnected) {
          this.echo.connector.pusher.connect(); // Attempt to reconnect
        }
    },
    goToNextItem() {
      if (this.activeItem === null) {
        this.activeItem = this.items[0];
      } else if (this.items.indexOf(this.activeItem) + 1 === this.items.length) {
        this.activeItem = this.items[0];
      } else {
        this.activeItem = this.items[this.items.indexOf(this.activeItem) + 1];
      }
      setTimeout(() => {
        this.goToNextItem()
      }, this.config.pub_images_time_interval ? this.config.pub_images_time_interval : this.activeItem && this.activeItem.type === 'video' ? 30000 : 10000);
    },
    /* playVoices() {
         if (!this.voices_to_play.length) {
             this.needsRecal = true;
             return;
         }
         this.needsRecal = false;
         this.voice_is_playing = true;
         const audio = new Audio('/sounds/slow-spring-board.mp3');
         audio.play();
         audio.addEventListener("ended", async () => {
             const snd = new Audio(
                 "data:audio/wav;base64," + this.voices_to_play.at(0)
             );
             try {
                 snd.play();
                 snd.addEventListener("ended", () => {
                     this.voice_is_playing = false;
                     this.voices_to_play.splice(0, 1);
                     if (this.voices_to_play.length) {
                         this.needsRecal = false;
                         this.playVoices();
                     } else {
                         this.needsRecal = true;
                     }
                     console.log('audioPlayed')
                 });
             } catch (err) {
                 console.log('audioNotPlayed')
                 console.log(err);
             }
         });
     },

     */
    initDisplay() {
      // this.config = JSON.parse(this.display ? this.display.config : JSON.parse(localStorage.getItem('display')))
      this.display = JSON.parse(localStorage.getItem('display'))
      if (this.display) {
        this.config = JSON.parse(this.display.config)
        this.theme = this.themes.indexOf(this.config.theme) === -1 ? 'liberrex' : this.config.theme
        this.color = this.config.color
        this.colorSecondary = this.config.colorSecondary
        this.colorText = this.config.colorText
        this.queues = this.config.queues || ''
        this.locale = this.config.locale || 'en'
        this.photo = this.config.photo !== '' ? this.config.photo : '/images/display-image.png'
        this.textCounter = this.config.textCounter || this.langCounter[this.locale]
        this.textNumber = this.config.textNumber || this.langNumber[this.locale]
        this.video_link = this.config.video_link;
        if (this.config.pub_images) {
          this.config.pub_images.forEach((img) => {

            if (img !== null && img !== 'null') {
              this.items.push(
                  {type: "image", src: img}
              )
            }

          })
        }

        if (this.video_link !== '' && this.video_link != null) {
          this.items.push(
              {
                type: "video", src: this.video_link
              }
          )
        }
      }
      this.goToNextItem();
      if (this.config.locale) {
        if (this.config.locale !== localStorage.getItem('Language')) {
          localStorage.setItem('Language', this.locale);
          setLocalVoiceBydisplayLang(this.locale);
        }
      } else {
        this.locale = 'en';
      }

    },
    setWidth() {
      if (this.$refs.dataTable) {
        let toRemove = 30;
        if (this.theme === 'leaf') {
          toRemove = 190;
        } else if (this.theme === 'biatSecond') {
          toRemove = 85;
        } else if (this.theme === 'biatFirst') {
          toRemove = 155;
        }
        this.getImageWidth = (window.innerWidth - toRemove) - this.$refs.dataTable.offsetWidth + "px";
      } else {
        setTimeout(() => {
          this.setWidth()
        }, 10)
      }
    },
    getDisplayQueues() {
      displayService.getInServiceTickets(this.queues).then(
          (data) => {
            data.tickets.forEach(ticket => {
              this.data.push(ticket)
            });
          }
      );

      this.echo.channel("private-display-" + this.display.id)
          .listen(
              ".ticket-call",
              (data) => {
                this.updateDataFromSocket(data)
              }
          )
          .listen(
              ".ticket-recall",
              (data) => {
                this.updateDataFromSocket(data)
              }
          ).listen(
          ".black-screen",
          (data) => {
            if (data.status) {
              this.blackScreen = true
            } else {
              this.blackScreen = false
            }
          }
      ) .listen(
          ".display-refresh",
          function () {
            displayService
                .refresh()
                .catch(
                    function (err) {
                      console.log(err)
                    }
                );
          }
      );
    },
    updateOnlineStatus(e) {
      const {type} = e
      this.onLine = type === 'online'
    },
    updateDataFromSocket(data) {
      let text = "";
      if (
          localStorage.getItem("number") &&
          localStorage.getItem("counter")
      ) {
        text =
            JSON.parse(localStorage.getItem("number")).voice +
            data.num_voice +
            JSON.parse(localStorage.getItem("counter")).voice +
            data.counter_voice;
      }

      this.voices_to_play.push(text);
      /*
        if (this.needsRecal) {
          this.playVoices();
        }
       */
      if (data.in_service) {
        this.data = data.in_service
      }

      setTimeout(() => {
        this.setWidth()
      }, 10)
    },
    ping() {
      try {
        html2canvas(document.body, {
          scale: 1, // scale canvas to 2x
        }).then(function (canvas) {
          canvas.toBlob(
              function (blob) {
                let form = new FormData();
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                  form.append("screenshot", reader.result);
                  displayService
                      .ping(form)
                      .catch(
                          function (err) {
                            console.log(err);
                          }
                      );
                };
              },
              "image/jpeg",
              0.05
          );
        });
      } catch (err) {
        console.log(err);
      }
    },
    callLogic() {
          this.snd.src = "data:audio/mpeg;base64," + this.voices_to_play[0];
          try {
            this.snd.play();
            this.snd.addEventListener("ended", () => {
              this.voice_is_playing = false;
              
            });
          } catch (err) {
            console.log(err);
          }
          this.voices_to_play.splice(0, 1);
    }
  },
  watch: {
    onLine(v) {
      if (v) {
        location.reload()
      }
    }
  },
  created() {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.setWidth()
      }, 10)
    },)
  },
  mounted() {
    if (localStorage.getItem('display') !== null) {
      if (JSON.parse(localStorage.getItem('display')).maintenance === 1) {
        router.push('/maintenance');
      }
    }
    setInterval(this.ping, process.env.VUE_APP_PING_INTERVAL ?? 300000)

    this.initDisplay()
    this.getDisplayQueues()
    setTimeout(() => {
      this.setWidth()
    }, 10)
    //this.playVoices();

    this.audio = this.audioNotification;
    
    setInterval(() => {
      if (this.voices_to_play.length > 0 && this.voice_is_playing === false) {
        this.voice_is_playing = true;
        

        this.audio.play();
        this.audio.addEventListener("ended", this.callLogic);
        
      }
    }, 1000);

    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    this.checkConnection();
    this.intervalId = setInterval(this.checkConnection, 40000); // 60000 ms = 1 minute
  },
  beforeUnmount() {
    this.echo.channel("private-display-" + this.display.id).stopListening('.ticket-call')
    this.echo.channel("private-display-" + this.display.id).stopListening('.ticket-recall')
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
    clearInterval(this.intervalId);
  },

  computed: {
    videoSrc() {
      return this.activeItem.src;
    },
    imageSrc() {
      return this.activeItem.src;
    }
  },
}
</script>

<style scoped>

.footer-container {
  width: 100% !important;
  height: auto;
}

.display-table {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  display: flex;

}

.display-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.display-image-container {
  align-content: center;
  width: v-bind(getImageWidth);
  padding-right: 2vmin;
  padding-left: 2vmin;
}

.display-image-container img {
  width: 100%;
  height: 60vh;
}

.display-image-container video {
  width: 100%;
  height: 60vh;
  margin: auto;
}

.biatVidContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

#black-screen {
  background-color: rgb(0, 0, 0);
  width: 100%;
  display: block;
  text-align: center;
  padding: 50vh;
  box-shadow: 0 0 5px #000;
  z-index: 99999999999;
  position: absolute;
}

@media (max-width:900px){
      .display-table {
        display: block;
      }
      .display-image-container img{
        height: 40vh
      }
    }
</style>