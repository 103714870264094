<template>
  <div>
    <div class="login-container">
      <lbrx-welcome-interface></lbrx-welcome-interface>
      <div class="form-footer-container">
        <lbrx-login-logo></lbrx-login-logo>
        <div class="login-form-container">
          <div style="width: 80%">
            <div v-if="errorLogin" class="error-container">
              <lbrx-error-notification :label="errors[errors.length-1]" color="#FF5757"
                                       background="rgba(255, 158, 152, 0.5)" height="10" width="96%"
                                       font-size="0.7" icon="error-icon"></lbrx-error-notification>
            </div>
            <div class="form-container" v-if="auto_logout">
              <div class="auth-title">
                {{ $t('Toasts.text_logout') }}
              </div>
              <div style="color: #008FCA;margin-top: 1.5vmin" class="auth-code auth-second-code">
                {{ business_name }}
              </div>
              <div style="color: #8A8A8A;margin-top: 1.5vmin;margin-bottom: 3vmin" class="auth-sub-title">
                {{ display_name }}
              </div>
              <lbrx-button class="margin-bottom" v-model="sending" icon="confirm-icon"
                           @clicked="logout()" :label="$t('Toasts.AcceptButton')" color="#FFFFFF"
                           background="#008FCA" height="10vmin" width="60%" font-size="1.1"></lbrx-button>
              <lbrx-button v-model="sending" icon="cancel-red-icon"
                           @clicked="cancelLogout()"
                           :label="$t('Toasts.CancelButton')" color="#FF5757" background="white" height="10vmin"
                           width="60%"
                           font-size="1.1" border="1px solid #FF5757"></lbrx-button>
            </div>
            <div class="form-container" v-else-if="!codeSet && !authenticated && codeChances !== 0">
              <div class="auth-title">
                {{ $t('Toasts.code') }}
              </div>
              <div class="auth-code">
                {{ authCode }}
              </div>
              <LbrxCountdown v-if="startCounter" color="#008FCA" v-model="startCounter" @counter-ended="counterEnded"
                             :timer="timer"></LbrxCountdown>
            </div>
            <div class="form-container" v-else-if="authenticated">
              <div class="auth-title">
                {{ $t('Toasts.text_1') }}
              </div>
              <div style="color: #008FCA;margin-top: 1.5vmin" class="auth-code auth-second-code">
                {{ business_name }}
              </div>
              <div style="color: #8A8A8A;margin-top: 1.5vmin;margin-bottom: 3vmin" class="auth-sub-title">
                {{ display_name }}
              </div>
              <lbrx-button class="margin-bottom" v-model="sending" icon="confirm-icon"
                           @clicked="SocketLogin()" :label="$t('Toasts.AcceptButton')" color="#FFFFFF"
                           background="#008FCA" height="10vmin" width="60%" font-size="1.1"></lbrx-button>
              <lbrx-button v-model="sending" icon="cancel-red-icon"
                           @clicked="this.$router.go(this.$router.currentRoute)"
                           :label="$t('Toasts.RejectButton')" color="#FF5757" background="white" height="10vmin"
                           width="60%"
                           font-size="1.1" border="1px solid #FF5757"></lbrx-button>
            </div>
            <div class="form-container" v-if="codeChances === 0 && !authenticated">
              <lbrx-button class="margin-bottom" color="#FFFFFF"
                           @clicked="this.$router.go(this.$router.currentRoute)"
                           label="Refresh"
                           background="#008FCA" height="10vmin" width="50%" font-size="1.1">
              </lbrx-button>
            </div>
          </div>
        </div>
        <div class="login-footer">
          <div class="footer-container">
            <div class="copyright">
              Liberrex.com © Copyright {{ new Date().getFullYear() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LbrxErrorNotification from "@/components/LbrxErrorNotification.vue";
import {mapActions} from "vuex";
import LbrxWelcomeInterface from "@/components/LbrxWelcomeInterface.vue";
import LbrxLoginLogo from "@/components/LbrxLoginLogo.vue";
import LbrxCountdown from "@/components/LbrxCountdown.vue";
import LbrxButton from "@/components/LbrxButton.vue";
import {displayService} from "@/_services/display.service";
import {setLocalVoiceBydisplayLang} from "@/_helpers/voice-helper";
import router from "@/router";

export default {
  name: "LoginPage",
  inject: ['echo', 'is_logout', 'business', 'display'],
  components: {
    LbrxButton,
    LbrxCountdown,
    LbrxLoginLogo,
    LbrxWelcomeInterface,
    LbrxErrorNotification
  },
  data() {
    return {
      //show login if not authenticated else logout
      authenticated: false,
      //while not authenticated :show counter if code is not set else show confirm login
      codeSet: false,
      codeChances: 2,
      //set true to start counter
      startCounter: false,
      //counter value
      timer: 60,
      //other values
      authCode: "***-***",
      business_name: '',
      display_name: '',
      errorLogin: false,
      sending: false,
      email: '',
      password: '',
      rememberMe: false,
      login_data: null,
      errors: [],
      auto_logout: false,
    }
  },
  watch: {},
  mounted() {
    if (this.is_logout) {
      console.log(this.is_logout)
      this.auto_logout = true
      this.business_name = this.business.name
      this.display_name = this.display.name
    } else {
      this.generateAuthCode()
      this.startCounter = true
    }

  },
  methods: {
    counterEnded() {
      this.startCounter = false
      this.codeChances -= 1
      if (this.codeChances > 0) {
        this.generateAuthCode()
        setTimeout(() => {
          this.startCounter = true
        }, 1)
      }
    },
    ...mapActions("authentication", ["login"]),
    validEmail(email) {
      let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm() {
      this.errors = [];

      if (!this.email.length) {
        this.errors.push("email required.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.");
      }
      if (!this.password.length) {
        this.errors.push("password required.");
      }
      if (!this.errors.length) {
        return true;
      }
      this.errorLogin = true;
      this.sending = false;
    },
    handleSubmit() {
      this.errorLogin = false;
      this.sending = true;
      if (this.checkForm()) {
        this.errorLogin = false;
        const {email, password} = this;
        this.login({email, password})
            .then(() => {
              this.sending = false;
            })
            .catch(() => {
              this.sending = false;
              this.errors.push("Email ou mot de passe incorrecte");
              this.errorLogin = true;
            });
      }
    },
    SocketLogin() {
      let payload = {
        display_id: this.login_data.display.id,
        auth_token: this.login_data.token
      }

      console.log(payload);
      displayService.confirmAuthByCode(payload).then((res) => {
        localStorage.setItem('display', JSON.stringify(this.login_data.display))
        localStorage.setItem('business', JSON.stringify(this.login_data.business))
        setLocalVoiceBydisplayLang(JSON.parse(this.login_data.display.config).locale)
      })

      // localStorage.setItem('token', this.login_data.token)
      // localStorage.setItem('display', JSON.stringify(this.login_data.display))
      //localStorage.setItem('business', JSON.stringify(this.login_data.business))
      // setLocalVoiceBydisplayLang(JSON.parse(this.login_data.display.config).locale)
    },
    generateAuthCode() {
      displayService
          .generateAuthCode()
          .then(
              function (res) {
                this.authCode = res.code.toString()
                    .replace(/(\d{3})(\d{3})/, "$1-$2");
                this.echo.channel("display-auth-" + res.code).listen(
                    ".display-auth",
                    function (socket_data) {
                      this.$i18n.locale = JSON.parse(socket_data.display.config).locale
                      this.business_name = socket_data.business.name
                      this.display_name = socket_data.display.name
                      this.login_data = socket_data
                      this.codeChances = 0
                      this.authenticated = true
                    }.bind(this)
                )
              }.bind(this)
          ).catch(
          function (err) {
            console.log(err)
          }.bind(this)
      );
    },
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('archived_token')
      localStorage.removeItem('display')
      localStorage.removeItem('business')
      this.echo.leaveAllChannels()
      location.reload()
    },
    cancelLogout() {
      localStorage.setItem('token', localStorage.getItem('archived_token'))
      localStorage.removeItem('archived_token')
      router.push('display')
    }
  }
}
</script>

<style scoped>
@media (max-width: 1200px) {
  .form-footer-container {
    justify-content: center !important;
    width: 100% !important;
  }

  .margin-bottom {
    margin-bottom: 2vmin !important;
  }
}

.error-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2vmin;
}

.footer-container {
  text-align: center;
  width: 50%;
}

.login-footer {
  width: 100%;
  display: flex;
  margin-bottom: 1vmin;
  justify-content: center;
}

.login-form-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: auto;
  padding-bottom: 13vh !important;
}

.login-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: auto !important;
}

.form-footer-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: space-between;
}

.auth-title {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 26px;

  /* Medium Gray */

  color: #8A8A8A;
}

.auth-second-code {
  font-size: 22px !important;
  line-height: 6vmin !important;
  font-weight: 400 !important;
}

.auth-code {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 94px;
  line-height: 14vmin;
  margin-top: 1.5vmin;

  color: #8A8A8A;
}

.auth-sub-title {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.1vmin;
  line-height: 3.8vmin;

  /* Primary */
  margin-bottom: 1.6vmin;
}
</style>
