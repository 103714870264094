import {createApp} from 'vue'
import 'mdb-vue-ui-kit/css/mdb.min.css'
import App from './App.vue'
import router from './router'

import store from "./store";
import Echo from "laravel-echo";

import Pusher from 'pusher-js';
window.Pusher = Pusher;


import {ApiConfigs, authHeader} from "@/_helpers";
import {displayService} from "@/_services/display.service";
import {createI18n} from "vue-i18n";


if (!localStorage.getItem('Language')) {
    localStorage.setItem('Language', 'fr')
}


import fr from "./locales/fr.json";
import en from "./locales/en.json";
import ar from "./locales/ar.json";


const echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: false,
    wsHost: (process.env.VUE_APP_EDIT_HOST === "true" && localStorage.getItem('host') != null) ? localStorage.getItem('ws') : process.env.VUE_APP_WSS_URL,
    wsPort: 6001,
    disableStats: true,
    authEndpoint: ApiConfigs.broadcast_url + ApiConfigs.pusher.auth,
    enabledTransports: ["ws", "wss"],
    cluster: 'eu',
    auth: {
        headers: {...authHeader()},
    },
});
const i18n = createI18n({
    locale: "fr",
    fallbackLocale: "fr",
    messages: {fr, en, ar},
});

const app = createApp(App).use(store).use(router).use(i18n)

if (localStorage.getItem('display') !== null) {

    app.provide('display', JSON.parse(localStorage.getItem('display') || ''))

    echo.channel("private-display-" + JSON.parse(localStorage.getItem('display') || "").id)
        .listen(
            ".display-refresh",
            function () {
                displayService
                    .refresh()
                    .catch(
                        function (err: any) {
                            console.log(err)
                        }
                    );
            }
        ).listen(
        ".display-logout",
        function () {
            localStorage.removeItem('display')
            localStorage.removeItem('business')
            echo.leaveAllChannels()
            router.push('/login')
        }.bind(this)
    ).listen(
        ".maintenance-change",
        function () {
            displayService
                .refresh()
                .then(function (err: any) {
                    location.reload()
                })
                .catch(
                    function (err: any) {
                        console.log(err)
                    })
        }.bind(this)
    );

}
const snd = new Audio();
const audioNotification = new Audio('/sounds/slow-spring-board.mp3');

app.provide('snd', snd)
app.provide('audioNotification', audioNotification)
app.provide('echo', echo)
app.mount('#app')
